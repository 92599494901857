import React from "react";
import { graphql, Link } from "gatsby";
// import Img from "gatsby-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import _ from "lodash";

import Layout from "../components/layout";
import SEO from "../components/seo";
import * as style from "./show.module.css";

// import style from  "./article.module.css";

// for BG IMAGE
// import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Button, Container } from "react-bootstrap";

export default ({ data }) => {
  const show = data.markdownRemark;
  const pluginImage = getImage(
    data.markdownRemark.frontmatter.featimg.childImageSharp.gatsbyImageData
  );

  return (
    <Layout>
      <SEO
        title={show.frontmatter.title}
        description={show.excerpt}
        image="/logo.png"
        pathname={show.fields.slug}
        // Boolean indicating whether this is an article:
        article
      />

      <BgImage image={pluginImage} className={style.fullscreen}>
        <Container className={style.show}>
          <show >
            {/* {show.frontmatter.featimg && (
          <figure >
        
            <GatsbyImage
              image={show.frontmatter.featimg.childImageSharp.gatsbyImageData}
              alt={show.frontmatter.title}
            />
          
          </figure>
        )} */}

            <h1 className={style.show__title}>{show.frontmatter.title}</h1>

            <div className={style.show__tax}>
              {show.frontmatter.subject.map((subject, index) => [
                index > 0 && ", ",
                <Link key={index} to={`/subjects/${_.kebabCase(subject)}`}>
                  {subject}
                </Link>,
              ])}
            </div>

            <div
              className={style.article__content}
              // See https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
              dangerouslySetInnerHTML={{ __html: show.html }}
            />
            <Button className="position-relative start-50 translate-middle" href="/gallery" variant="danger">
              back 
            </Button>
          </show>
        </Container>
      </BgImage>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date
        subject
        author
        featimg {
          childImageSharp {
            gatsbyImageData(
              quality: 50
              webpOptions: { quality: 70 }
              width: 2000
            )
          }
        }
      }
    }
  }
`;
